import * as React from 'react';
import Heading from '@lce/slice_v2/Elements/Heading';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import LegalHtmlProvider from '@lce/intl-ui/src/Provider/LegalHtmlProvider';
import Container from '@lce/intl-ui/src/Layout/Container';

import Layout from '../../ui/ConnectedLayout';

export interface ILegalProps extends IDefaultPageProps {
  pageContext: {
    originalPath?: string;
    body: any;
    title?: string;
  };
}

const LegalTemplate: React.FC<ILegalProps> = ({ pageContext: { title, body }, ...props }) => (
  <Layout data-testid="LegalTemplate" {...props} >
    <Container sx={{ fontFamily: 'tertiary', p: '4rem 0.75rem' }}>
      <section>
        {title && <Heading as="h1" data-testid="LegalTemplateTitle" sx={{
          fontSize: ['2rem', '2.75rem'],
          color: 'primary',
          textAlign: 'center',
        }}>{title}</Heading>}
        <LegalHtmlProvider>
          {body && <div dangerouslySetInnerHTML={{ __html: body }} />}
        </LegalHtmlProvider>
      </section>
    </Container>
  </Layout>
);

export default LegalTemplate;
